export const state = () => ({
    config: [],
    users: [],
    countries: [],
    constants: {
        bannedChars: [
            'Á', 'Í', 'Ű', 'Ő', 'Ü', 'Ö', 'Ú', 'Ó', 'É',
            'á', 'í', 'ű', 'ő', 'ü', 'ö', 'ú', 'ó', 'é',
            '&', '<', '>', ':', '"', "'", '/', "\\", '|',
            '?', '*', '#', '%', '{', '}', '$', '!', '@',
            '`', '='
        ],
    },
});

export const getters = {
    getConstants(state) {
        return state.constants;
    },
    getConfig(state) {
        return state.config;
    },
    getUsers(state) {
        return state.users;
    },
    getCountries(state) {
        return state.countries;
    },
};

export const mutations = {
    setConfig(state, config) {
        state.config = config;
    },
    setUsers(state, users) {
        const roles = [];
        for (const role of state.config.users.user_roles) {
            if (role.id !== 'ROLE_USER') {
                roles.push(role.id);
            }
        }

        for (const role of roles) {
            if (users[role]) {
                for (const user of users[role]) {
                    if (!state.users.find((data) => data.id === user.id) && user.state === 1) {
                        state.users.push(user);
                    }
                }
            }
        }

        state.users.sort((a, b) => {
            const aInitialLetter = a.fullName.split('')[0].toLowerCase();
            const bInitialLetter = b.fullName.split('')[0].toLowerCase();
            if (
                (aInitialLetter === 'a' && bInitialLetter === 'á') ||
                (aInitialLetter === 'e' && bInitialLetter === 'é') ||
                (aInitialLetter === 'i' && bInitialLetter === 'í') ||
                (aInitialLetter === 'o' && bInitialLetter === 'ó') ||
                (aInitialLetter === 'ö' && bInitialLetter === 'ő') ||
                (aInitialLetter === 'u' && bInitialLetter === 'ú') ||
                (aInitialLetter === 'ü' && bInitialLetter === 'ű')
            ) {
                return -1;
            } else if (
                (bInitialLetter === 'a' && aInitialLetter === 'á') ||
                (bInitialLetter === 'e' && aInitialLetter === 'é') ||
                (bInitialLetter === 'i' && aInitialLetter === 'í') ||
                (bInitialLetter === 'o' && aInitialLetter === 'ó') ||
                (bInitialLetter === 'ö' && aInitialLetter === 'ő') ||
                (bInitialLetter === 'u' && aInitialLetter === 'ú') ||
                (bInitialLetter === 'ü' && aInitialLetter === 'ű')
            ) {
                return 1;
            }

            return a.fullName.localeCompare(b.fullName, 'hu');
        });
    },
    setCountries(state, countries) {
        state.countries = Object.entries(countries).map((key) => ({
            id: key[0],
            label: key[1],
        }));
    },
};

export const actions = {
    async fetchConfig(context) {
        await this.$axios
            .$get(`${process.env.prodAPI}/config`)
            .then((res) => {
                const configPayload = { ...res.result };
                configPayload.users.artist_job_categories = [
                    {
                        id: 0,
                        label: 'Comp',
                    },
                    {
                        id: 1,
                        label: 'Anim',
                    },
                    {
                        id: 2,
                        label: 'Model',
                    },
                    {
                        id: 3,
                        label: 'Texture / DMP',
                    },
                    {
                        id: 4,
                        label: 'Light / Render',
                    },
                    {
                        id: 5,
                        label: 'FX',
                    },
                    {
                        id: 6,
                        label: 'Flame',
                    },
                    {
                        id: 7,
                        label: 'Nuke',
                    },
                    {
                        id: 8,
                        label: 'Maya',
                    },
                ];
                context.commit('setConfig', configPayload);
                context.commit('setUsers', res.result.users.members);
                context.commit('setCountries', res.result.intl.countries);
            })
            .catch(this.$catchGetFatal);
    },
};

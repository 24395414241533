<template>
    <div>
        <nuxt-link v-if="id" :to="'/users/' + id">
            <slot name="beforeBadge" />
            <user-badge-inside
                link
                :user="actualUser"
                :icon="icon"
                :monograms="monograms"
                :short-name="shortName"
                :full-name="fullName"
                :is-small="isSmall"
                :class="additionalClasses"
                :arrows="arrows"
                :color="color"
                :disable-tooltip="disableTooltip"
                :job-title="jobTitle"
                :system="system"
                class="flex items-center transition-colors dark:text-white"
            />
        </nuxt-link>
        <div v-else class="flex items-center" :class="additionalClasses">
            <slot name="beforeBadge" />
            <user-badge-inside
                :user="actualUser"
                :icon="icon"
                :monograms="monograms"
                :short-name="shortName"
                :full-name="fullName"
                :is-small="isSmall"
                :class="additionalClasses"
                :color="color"
                :disable-tooltip="disableTooltip"
                :job-title="jobTitle"
                :system="system"
                class="flex items-center transition-colors dark:text-white"
            />
        </div>
    </div>
</template>

<script>
import UserBadgeInside from './UserBadgeInside.vue';
export default {
    components: {
        UserBadgeInside,
    },
    props: {
        id: { type: [Boolean, Number], required: false, default: 0 },
        user: { type: [Object, String], required: true },
        additionalClasses: { type: String, required: false, default: '' },
        monograms: { type: Boolean, required: false },
        shortName: { type: Boolean, required: false },
        fullName: { type: Boolean, required: false },
        isSmall: { type: Boolean, required: false },
        icon: { type: Boolean, required: false, default: false },
        disableTooltip: { type: Boolean, required: false, default: false },
        arrows: { type: Boolean, required: false, default: true },
        color: { type: String, required: false, default: 'secondary' },
        jobTitle: { type: String, required: false, default: '' },
        system: { type: Boolean, required: false, default: false },
    },
    computed: {
        actualUser() {
            if (typeof this.user === "object") return this.user;
            else try {
                return JSON.parse(this.user);
            } catch(e) {
                return {};
            }
        },
    },
};
</script>

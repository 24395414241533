<template>
    <div class="task_history">
        <div class="form-label text-gray-400">Activities</div>
        <ul>
            <li v-for="(item, i)  in logs" :key="i">
                <div class="task_history_element">
                    <div class="task_history_meta">
                        <UserBadge :user="item.created_meta" icon /> - <date :date="item.created_meta.date" />
                    </div>
                    <div class="task_history_text" v-html="convertToClickableLinks(item.message)" ></div>

                </div>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: {
            logs: { type: Array, required: true},
        },
        data() {
            return {
                log: this.logs,
            };
        },
        methods: {
            convertToClickableLinks(inputText)
            {
                inputText = inputText.replaceAll(/<\/[^>]+(>|$)/g, "");
                const regex = /(https?:\/\/\S+)/gi;
                return inputText.replace(regex, function (match) {
                    // Ellenőrzés, hogy a link már <a> tagben van-e
                    if (/<a\b[^>]*>.*?<\/a>/i.test(match)) {
                        return match; // Ha igen, ne végezze el a módosítást
                    } else {
                        return '<a target="_blank" href="' + match + '">' + match + '</a>';
                    }
                });
            }
        }
    };
</script>
<style>
.task_history h2{
    margin-top: 24px;
    margin-bottom: 24px;
}
.task_history ul a {
    color: #1a9aef;
}
.task_history .task_history_element {
    margin-bottom: 24px;
    font-size: 12px;
    padding-bottom: 8px;
    border-bottom: #2e2e2e 1px solid;
}
.task_history .task_history_element .task_history_meta {
    display: flex;
    gap: 12px;
    align-items: center;
}
.task_history .task_history_text {
    margin-top: 12px;
    line-height: 1.5;
    word-break: break-all;
}
</style>
export default function ({ redirect, route, store, error }) {
    // We are only looking for project routes.
    if (!/^projects-id/.test(route.name)) return;

    const project = store.getters['project/getActiveProject'];

    if (!project.id) {
        return error({ statusCode: 404, message: 'This project has been deleted, or never existed.' });
    }

    // for projects in PREPRODUCTION state
    if (['preproduction', 'on_hold_pre'].includes(project.status)) {
        if (!/projects-id-preproduction/.test(route.name) || /^projects-id-preproduction$/.test(route.name)) {
            return redirect(`/projects/${route.params.id}/preproduction/resources`);
        }
    }

    // for projects with IN PROGRESS or READY TO ARCHIVE status
    if (/^(in_progress|on_hold_wip|ready_to_archive)$/.test(project.status)) {
        if (/preproduction/.test(route.name)) {
            return redirect(`/projects/${route.params.id}/work`);
        }
        // e.g. /project/378 and /project/378/work are not valid pages. we are looking for the first shot,
        // which is fetched on projects-id-work-shots-shot
        if (/^(projects-id|projects-id-work)$/.test(route.name)) {
            return redirect(`/projects/${route.params.id}/work/shots`);
        }

        if (/^projects-id-work-shots-shot/.test(route.name) && route.params.shot === 'undefined') {
            return redirect(`/projects/${route.params.id}`);
        }

        if (/^projects-id-work-shots-shot$/.test(route.name) && route.params.shot) {
            return redirect(
                `/projects/${route.params.id}/work/shots/${route.params.shot}${
                    store.state.routing.savedShotVersionRoutes[route.params.shot] || '/comp'
                }`
            );
        }
    }

    if (route.name === 'projects-id-details') {
        return redirect(`/projects/${route.params.id}/details/general_information`);
    }
}

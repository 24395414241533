<template>
    <!--    <validation-observer ref="observer" v-slot="{ invalid }">-->
    <validation-observer ref="observer">
        <header class="event-view-header flex items-center justify-between px-8 pt-4">
            <div class="inline-flex items-center">
                <div class="text-xl mr-4">Task</div>
                <project-badge v-if="task && task.project" :project="task.project" icon-only large />
                <h2 class="card-title mb-0">
                    {{ isNewTask ? 'Create task' : initialData ? initialData.name : '' }}
                </h2>
                <div
                    v-if="initialData && initialData.project"
                    class="font-gotham-500 text-xs text-gray-400 ml-1 translate-y-0.5 p-1 pt-2 mb-1"
                    :class="{'cursor-pointer': !isNewTask && task.type}"
                    @click="handleTitleLinkClick"
                >
                    {{ initialData && !isNewTask ? '/ ' + initialData.project.name : '' }}
                    <span
                        v-if="task.type && task.type.id === 3 && task.subject && task.subject.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ Bid"}}
                    </span>
                    <span
                        v-if="task.type && task.type.id === 4 && task.subject && task.subject.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ Resource"}}
                    </span>
                    <span
                        v-if="task.type && task.type.id === 5 && task.subject && task.subject.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ " + task.subject.name }}
                    </span>
                    <span
                        v-if="task.type && task.type.id === 6 && task.subject && task.subject.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ Daily"}}
                    </span>
                    <span
                        v-if="task.type && task.type.id === 7 && task.subject && task.subject.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ Broadcast"}}
                    </span>
                    <span
                        v-if="task.type && task.type.id === 8 && task.subject && task.subject.shot && task.subject.shot.name"
                        class="font-gotham-500 text-xs text-gray-400 translate-y-0.5"
                    >
                        {{"/ " + task.subject.shot.name }} / {{ task.subject.passType }} / v{{ task.subject.version }}
                    </span>
                </div>
            </div>
            <div class="buttons">
                <v-btn icon small :to="$route.fullPath" target="_blank">
                    <i class="las la-external-link-alt" />
                </v-btn>
                <span class="ml-4">|</span>
                <v-btn icon class="ml-2" @click="$emit('updateTaskVisibility', false)">
                    <i class="las la-times" />
                </v-btn>
            </div>
        </header>
        <v-form class="relative" @submit.prevent="onSave(task.id, false)">
            <div class="grid grid-cols-6 gap-x-12 gap-y-8 p-8">
                <div class="col-span-4" >
                    <div
                        v-if="!isNewTask && task && task.type && [4, 5, 6, 7, 8].indexOf(task.type.id) !== -1"
                    >
                        {{/* Resource type task */}}
                        <div v-if="task.type.id === 4" class="aspect-w-1">
                            <div class="absolute w-full h-full bg-gray-300 flex items-center justify-center">
                                <i class="las la-file-alt text-2xl" />
                            </div>
                        </div>

                        {{/* Shot type task */}}
                        <div v-if="task.type.id === 5 && task.subject" class="aspect-w-1 relative">
                            <img :src="shotThumbnailPath" class="w-full h-full object-cover object-center" alt="" />
                        </div>

                        {{/* Shot type task */}}
                        <div v-if="task.type.id === 6 && task.subject" class="relative">
                            <img :src="shotThumbnailPath" class="w-full h-full object-cover object-center" alt="" />
                        </div>

                        {{/* Submit type task */}}
                        <div v-if="task.type.id === 8" class="relative">
                            <base-video-player
                                v-if="task.subject && task.subject.passType && !!task.subject.passType.match(/(CG|Comp)/)"
                                :submit="task.subject"
                            />
                            <base-audio-player
                                v-if="task.subject && task.subject.passType === 'Audio'"
                                :submit="task.subject"
                                small
                            />
                            <div v-if="task.subject && task.subject.passType === 'Image'" class="aspect-w-1">
                                <img
                                    :src="submitImageThumbnailPath"
                                    class="w-full h-full object-contain object-cover"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="task.description && getModalViewType().id === 'view'" class="col-span-3 mt-4">
                        <span class="form-label text-gray-400">Description</span>
                        <div v-if="task.description" class="ck-content" v-html="task.description"></div>
                    </div>
                    <div v-if="getModalViewType().id === 'edit'" class="col-span-2">
                        <label class="form-label">Description</label>
                        <ck-editor
                            v-if="$checkPermission('task.create') || $checkPermission('task.update')"
                            v-model="task.description"
                        />
                        <div v-else class="mt-4" v-html="task.description"></div>
                    </div>
                </div>

                <div
                    v-if="getModalViewType().id === 'edit'"
                    class="col-span-2"
                >
                    <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                        {{/* Name */}}
                        <div class="col-span-6">
                            <label class="form-label">Name*</label>
                            <validation-provider v-slot="{ errors }" vid="name" name="Name" class="relative block">
                                <v-text-field
                                    v-model="task.name"
                                    required
                                    solo
                                    dense
                                    autofocus
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </div>

                        {{/* Selections */}}
                        <div class="col-span-6 grid grid-cols-8 gap-y-6 gap-x-4">
                            {{/* Type */}}
                            <div class="col-span-4">
                                <label class="form-label">Type*</label>
                                <validation-provider v-slot="{ errors }" vid="entityId" name="Type">
                                    <v-select
                                        v-model="selections.type"
                                        solo
                                        dense
                                        return-object
                                        required
                                        :items="taskTypes"
                                        :item-text="(item) => item.label"
                                        :error-messages="errors"
                                        @input="handleChange('type')"
                                    />
                                </validation-provider>
                            </div>

                            {{/* Project */}}
                            <div  v-if="selections.type.id !== 1" class="col-span-4">
                                <label class="form-label">Project*</label>
                                <validation-provider v-slot="{ errors }" name="Project">
                                    <v-autocomplete
                                        v-model="selections.project"
                                        name="Project"
                                        :items="entities.projects"
                                        :item-text="(item) => item.name"
                                        :error-messages="errors"
                                        :menu-props="{ closeOnContentClick: true }"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        @input="handleChange('project')"
                                    >
                                        <template #selection="datas">
                                            <project-badge
                                                v-if="!loading.project"
                                                :project="datas.item"
                                                is-small
                                                truncate
                                                @click="datas.select"
                                            />
                                        </template>
                                        <template #item="{ item }">
                                            <project-badge :project="item" is-small />
                                        </template>
                                    </v-autocomplete>
                                </validation-provider>
                                <transition name="fade">
                                    <div
                                        v-if="loading.project"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </div>

                            {{/* Bid */}}
                            <div v-if="selections.type.id === 3" class="col-span-8">
                                <validation-provider v-slot="{ errors }" name="Bid">
                                    <label class="form-label">Bid*</label>
                                    <v-select
                                        v-model="selections.bid"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.bids"
                                        :error-messages="errors"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.bid" class="selection-wrap v-select__selection--comma">
                                                {{ item.name }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.name }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.bid"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* ResourceType */}}
                            <div v-if="selections.type.id === 4" class="col-span-6">
                                <validation-provider v-slot="{ errors }" name="Resource Type">
                                    <label class="form-label">Resource type*</label>
                                    <v-select
                                        v-model="selections.resourceType"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.resourceTypes"
                                        :error-messages="errors"
                                        @input="handleChange('resourceType')"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.resourceType" class="selection-wrap v-select__selection--comma">
                                                {{ item.name }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.name }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.resourceType"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Resource (version) */}}
                            <div v-if="selections.type.id === 4" class="col-span-2">
                                <validation-provider v-slot="{ errors }" name="Resource">
                                    <label class="form-label">Version*</label>
                                    <v-select
                                        v-model="selections.resource"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.resources"
                                        :error-messages="errors"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.resource" class="v-select__selection--comma">
                                                {{ `v${item.version}` }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ `v${item.version}` }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.resource"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Daily */}}
                            <div v-if="selections.type.id === 6" class="col-span-8">
                                <validation-provider v-slot="{ errors }" name="Daily">
                                    <label class="form-label">Daily*</label>
                                    <v-select
                                        v-model="selections.daily"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.dailies"
                                        :error-messages="errors"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.daily" class="selection-wrap v-select__selection--comma">
                                                {{ `${item.submit.pass.name} v${item.clientVersion} (${item.preset}) #${item.id}` }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ `${item.submit.pass.name} v${item.clientVersion} (${item.preset}) #${item.id}` }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.daily"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Broadcast */}}
                            <div v-if="selections.type.id === 7" class="col-span-8">
                                <validation-provider v-slot="{ errors }" name="Broadcast">
                                    <label class="form-label">Broadcast*</label>
                                    <v-select
                                        v-model="selections.broadcast"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.broadcasts"
                                        :error-messages="errors"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.broadcast" class="selection-wrap v-select__selection--comma">
                                                {{ `${item.submit.pass.name} - ${item.filmCode} (${item.preset.name}) #${item.id}` }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ `${item.submit.pass.name} - ${item.filmCode} (${item.preset.name}) #${item.id}` }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.broadcast"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Group */}}
                            <div v-if="[5,8].includes(selections.type.id)" class="col-span-4">
                                <validation-provider v-slot="{ errors }" name="Sequence">
                                    <label class="form-label">Group*</label>
                                    <v-select
                                        v-model="selections.sequence"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.sequences"
                                        :error-messages="errors"
                                        @input="handleChange('sequence')"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.sequence" class="selection-wrap v-select__selection--comma">
                                                {{ item.name }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.name }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.sequence"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Shot */}}
                            <div v-if="[5,8].includes(selections.type.id)" class="col-span-4">
                                <validation-provider v-slot="{ errors }" name="Shot">
                                    <label class="form-label">Shot*</label>
                                    <v-select
                                        v-model="selections.shot"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.shots"
                                        :error-messages="errors"
                                        @input="handleChange('shot')"
                                    >
                                        <template #selection="{ item }">
                                            <span class="selection-wrap v-select__selection--comma">
                                                {{ item.name }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.name }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.shot"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Pass type */}}
                            <div v-if="selections.type.id === 8" class="col-span-2">
                                <validation-provider v-slot="{ errors }" name="Pass type">
                                    <label class="form-label">Pass type*</label>
                                    <v-select
                                        v-model="selections.passType"
                                        solo
                                        dense
                                        return-object
                                        :items="entities.passTypes"
                                        :error-messages="errors"
                                        @input="handleChange('passType')"
                                    >
                                        <template #selection="{ item }">
                                            <span class="v-select__selection--comma">
                                                {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                                        </template>
                                    </v-select>
                                </validation-provider>
                            </div>

                            {{/* Pass */}}
                            <div v-if="selections.type.id === 8" class="col-span-4">
                                <validation-provider v-slot="{ errors }" name="Pass">
                                    <label class="form-label">Pass*</label>
                                    <v-select
                                        v-model="selections.pass"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.passes"
                                        :error-messages="errors"
                                        @input="handleChange('pass')"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.pass" class="selection-wrap v-select__selection--comma">
                                                {{ item.name }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ item.name }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.pass"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>

                            {{/* Submit */}}
                            <div v-if="selections.type.id === 8" class="col-span-2">
                                <validation-provider v-slot="{ errors }" name="Submit">
                                    <label class="form-label">Version*</label>
                                    <v-select
                                        v-model="selections.submit"
                                        solo
                                        dense
                                        clearable
                                        return-object
                                        :items="entities.submits"
                                        :error-messages="errors"
                                    >
                                        <template #selection="{ item }">
                                            <span v-if="!loading.submit" class="v-select__selection--comma">
                                                {{ `v${item.version}` }}
                                            </span>
                                        </template>
                                        <template #item="{ item }">
                                            {{ `v${item.version}` }}
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="loading.submit"
                                            class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>
                        </div>

                        {{/* Start date */}}
                        <div class="col-span-3">
                            <validation-provider v-slot="{ errors }" vid="dateStart" name="dateStart">
                                <label class="form-label">
                                    Task start date{{ isDispoCreationInProgress ? '*' : '' }}
                                </label>
                                <date-picker
                                    mode="date"
                                    :required="isDispoCreationInProgress"
                                    :date="task.dateStart || new Date()"
                                    :value="task.dateStart || new Date()"
                                    @updateDate="(e) => (task.dateStart = $toISO(e))"
                                />
                                <input-error :errors="errors" />
                            </validation-provider>
                        </div>

                        {{/* End date */}}
                        <div class="col-span-3">
                            <validation-provider v-slot="{ errors }" vid="dateEnd" name="dateEnd">
                                <label class="form-label">
                                    Task end date
                                </label>
                                <date-picker
                                    mode="date"
                                    :date="task.dateEnd"
                                    :value="task.dateEnd"
                                    @updateDate="(e) => (task.dateEnd = $toISO(e))"
                                />
                                <input-error :errors="errors" />
                            </validation-provider>
                        </div>

                        {{/* Assignee */}}
                        <div class="col-span-6">
                            <label class="form-label">Assignee*</label>
                            <validation-provider v-slot="{ errors }" vid="assignee" name="Assignee">
                                <v-autocomplete
                                    v-if="users && me"
                                    v-model="task.assignee"
                                    name="assigned_to"
                                    :items="sortedUsers"
                                    :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                    :error-messages="errors"
                                    :menu-props="{ closeOnContentClick: true }"
                                    class="user-badges"
                                    placeholder="Select a user"
                                    solo
                                    dense
                                    return-object
                                >
                                    <template #selection="datas">
                                        <user-badge :user="datas.item" full-name is-small @click="datas.select" />
                                    </template>
                                    <template #item="{ item }">
                                        <div class="w-full">
                                            <user-badge :user="item" full-name />
                                            <div v-if="item.hasLine" class="border-b border-gray-150 mt-2" />
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </validation-provider>
                        </div>

                        {{/* Review by */}}
                        <div class="col-span-6" >
                            <label class="form-label">Review by*</label>
                            <validation-provider
                                v-slot="{ errors }"
                                vid="creator"
                                name="Creator"
                                class="relative block"
                            >
                                <v-autocomplete
                                    v-model="task.created_meta"
                                    name="review_by"
                                    :items="reviewMembers"
                                    :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                    :error-messages="errors"
                                    :menu-props="{ closeOnContentClick: true }"
                                    class="user-badges"
                                    placeholder="Select a user"
                                    solo
                                    dense
                                    return-object
                                >
                                    <template #selection="datas">
                                        <user-badge :user="datas.item" full-name is-small />
                                    </template>
                                    <template #item="{ item }">
                                        <user-badge :user="item" full-name />
                                    </template>
                                </v-autocomplete>
                            </validation-provider>
                        </div>

                        {{/* Notify members */}}
                        <div v-if="isNewTask && sortedUsers.length" class="col-span-6">
                            <label class="form-label">Notify members:</label>
                            <v-autocomplete
                                v-model="task.usersToNotify"
                                :items="sortedUsers"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                label="Notify members"
                                multiple
                                solo
                                dense
                                chips
                                return-object
                                deletable-chips
                            >
                                <template #selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="
                                            task.usersToNotify = task.usersToNotify.filter(
                                                (user) => user.id !== data.item.id
                                            )
                                        "
                                    >
                                        <user-badge :user="data.item" is-small full-name />
                                    </v-chip>
                                </template>
                                <template #item="{ item }">
                                    <div :key="Math.random()" class="w-full">
                                        <user-badge :user="item" full-name />
                                        <div v-if="item.hasLine" class="border-b border-gray-150 mt-2" />
                                    </div>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                </div>

                <div v-else class="col-span-2">
                    {{/* * */}}
                    {{/* * */}}
                    {{/* * */}}
                    {{/* VIEW ONLY mode, no inputs (the only input is the status changer select at the bottom to update task status) */}}
                    {{/* * */}}

                    <div class="h-full flex flex-col justify-between">
                        <div class="grid grid-cols-3 gap-x-5 gap-y-8">

                            <div v-if="task.assignee" class="col-span-1">
                                <span class="form-label text-gray-400">Assignee</span>
                                <user-badge :user="task.assignee" is-small short-name />
                            </div>

                            <div v-if="task.created_meta" class="col-span-1">
                                <span class="form-label text-gray-400">Review by</span>
                                <user-badge :user="task.created_meta" is-small short-name />
                            </div>

                            <div v-if="task.reviewer" class="col-span-1">
                                <span class="form-label text-gray-400">Created by</span>
                                <user-badge :user="task.reviewer" is-small short-name />
                            </div>

                            <div v-if="task.assignee" class="col-span-1">
                                <span class="form-label text-gray-400">Created at</span>
                                <div class="text-xs">
                                    {{ $moment(task.created_meta.date).format('YYYY. MMM. DD. HH:mm') }}
                                </div>
                            </div>



                            <div class="col-span-1">
                                <span class="form-label text-gray-400">Start date</span>
                                <div class="text-xs">
                                    {{ $moment(task.dateStart).format('YYYY. MMM. D.') }}
                                </div>
                            </div>

                            <div class="col-span-1">
                                <span class="form-label text-gray-400">End date</span>
                                <div class="text-xs">
                                    {{ task.dateEnd ? $moment(task.dateEnd).format('YYYY. MMM. D.') : '-' }}
                                </div>
                            </div>

                            {{/* if task is of bid type */}}
                            <div v-if="task.type && task.type.id === 3" class="col-span-2">
                                <div class="form-label text-gray-600">Bid name</div>
                                <div>{{ task.subject.name }}</div>
                            </div>

                            {{/* if task is of shot type */}}
                            <div v-if="task.type && task.type.id === 5" class="col-span-2">
                                <div class="form-label text-gray-600">Shot name</div>
                                <div>{{ task.subject.name }}</div>
                            </div>

                            <TaskLog :logs="logs" class="col-span-3" />
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* VIEW MODE BUTTONS */}}
                {{/* * */}}
            </div>
            <footer
                class="shadow-footer fixed bg-white dark:bg-dark-850 flex justify-between w-1/1 xl:w-3/4 bottom-0 right-0 px-8 py-4"
            >
                <div>
                    <v-btn @click="closeTask">Cancel</v-btn>
                    <v-btn
                        v-if="getModalViewType().id === 'view'"
                        @click="updateSendMessage(true, task.id, 9, task.assignee.id, task.project.id)"
                    >
                        <i class="las la-envelope mr-2" />
                        Send message
                    </v-btn>
                </div>
                <div class="flex items-center justify-end gap-4">
                    <div v-if="getModalViewType().id === 'view'" class="inline-flex items-center">
                        <i v-if="postInProgress" class="animate-spin las la-circle-notch mr-3" />

                        <v-select
                            v-if="$checkPermission('task.update')"
                            v-model="taskStatus"
                            :items="filteredTaskStatusOptions"
                            :item-text="(item) => item.label"
                            solo
                            dense
                            :disabled="postInProgress"
                            return-object
                            hide-details
                            @change="e => onSave(task.id, true, e)"
                        />
                    </div>
                    <div v-if="getModalViewType().id !== 'view' && isNewTask && $checkPermission('task.create')">
                        <v-btn :disabled="!canSave" color="primary" type="submit">
                            <i class="las la-save mr-2" />
                            Save
                        </v-btn>
                    </div>
                    <div v-else-if="me.roles.includes('ROLE_ADMIN') || (task.created_meta && !me.roles.includes('ROLE_ADMIN') && $checkPermission('task.update'))">
                        <div v-if="!isEditModeActive">
                            <v-btn color="primary" @click="isEditModeActive = true">
                                <i class="las la-pen mr-2" />
                                Edit
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="secondary" @click="isEditModeActive = false">
                                Discard changes
                            </v-btn>
                            <v-btn :disabled="!canSave" color="primary" type="submit">
                                <i class="las la-save mr-2" />
                                Save
                            </v-btn>
                        </div>
                    </div>
                </div>
            </footer>
        </v-form>
        <task-comment-modal
            :task="taskId"
            :status="task.status ? task.status.id : null"
            :open="saveWithComment"
            @canceled="cancelStatusUpdate"
            @confirmed="editTask"
        />
    </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseVideoPlayer from '~/components/UI/Base/BaseVideoPlayer';
import BaseAudioPlayer from '~/components/UI/Base/BaseAudioPlayer';
import TaskLog from "@/components/Tasks/TaskLog.vue";
import TaskCommentModal from "@/components/Tasks/TaskCommentModal.vue";

export default {
    components: {
        TaskCommentModal,
        TaskLog,
        BaseVideoPlayer,
        BaseAudioPlayer,
    },
    props: {
        taskData: { type: Object, required: true, default: null },
    },
    data() {
        return {
            isEditModeActive: false,
            taskStatusOptions: [],
            filteredTaskStatusOptions: [],
            initialData: null,
            difference: null,
            isInternalAudioLoading: null,
            isDownloadInProgress: null,
            postInProgress: false,
            saveWithComment: false,
            sortedUsers: [],
            taskStatus: null,
            task: {
                project: null,
                type: null,
                entity: null,
                assignee: [],
                logs: [],
                status: null,
                created_meta: {},
            },
            prevStatus: null,
            selections: {
                type: {id: 2, label: 'Project'},
                project: null,
                sequence: null,
                shot: null,
                passType: 'CG',
                pass: null,
                submit: null,
                bid: null,
                resourceType: null,
                resource: null,
                daily: null,
                broadcast: null,
            },
            entities: {
                projects: [],
                sequences: [],
                shots: [],
                passTypes: ['CG', 'Comp', 'Image', 'Audio'],
                passes: [],
                submits: [],
                bids: [],
                resourceTypes: [],
                resources: [],
                dailies: [],
                broadcasts: [],
            },
            loading: {
                project: false,
                sequence: false,
                shot: false,
                pass: false,
                submit: false,
                bid: false,
                resourceType: false,
                resource: false,
                daily: false,
                broadcast: false,
            },
            logs: [],
            taskId: null,
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
            users: 'config/getUsers',
            me: 'users/me/getMe',
            isNewTask: 'tasks/getShowNewTask',
        }),
        isDispoCreationInProgress() {
            return this.$route.fullPath === '/dispo/users/new';
        },
        shotThumbnailPath() {
            if (
                this.task.type.id === 6 &&
                this.task.subject &&
                this.task.subject.submit &&
                this.task.subject.submit.shot &&
                this.task.subject.submit.shot.thumbnailPath
            ) {
                return `${process.env.backendURL}/${this.task.subject.submit.shot.thumbnailPath}`;
            } else if (!this.task.subject || !this.task.subject.thumbnailPath) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }
            return `${process.env.backendURL}/${this.task.subject.thumbnailPath}`;
        },
        submitImageThumbnailPath() {
            return `${process.env.backendURL}/${this.task.subject.thumbnailPath}`;
        },
        taskTypes() {
            return this.$getTaskTypesFilteredByUserPermission();
        },
        canSave() {
            if (this.postInProgress) return false;
            if (this.isNewTask) {
                if (!this.task?.name?.length) return false;
                if (!this.task?.assignee) return false;
            }
            for (const key of Object.keys(this.loading)) {
                if (this.loading[key]) {
                    return false;
                }
            }
            switch (this.selections.type.id) {
                case 1: // General
                    return true;
                case 2: // Project
                    return !!this.selections.project;
                case 3: // Bid
                    return !!this.selections.bid;
                case 4: // Resource
                    return !!this.selections.resource;
                case 5: // Shot
                    return !!this.selections.shot;
                case 6: // Daily
                    return !!this.selections.daily;
                case 7: // Broadcast
                    return !!this.selections.broadcast;
                case 8: // Submit
                    return !!this.selections.submit;
                default:
                    return false;
            }
        },
        reviewMembers() {
            return this.sortedUsers.filter(u => !u.roles.includes('ROLE_ARTIST'));
        }
    },
    watch: {
        task: {
            handler(value) {
                this.taskStatus = value.status;
                if (value) {
                    this.difference = this.$difference(value, this.initialData || {});
                }
            },
            deep: true,
        },
        isEditModeActive(e) {
            if (e === true) {
                this.populateSelections();
            }
        }
    },
    mounted() {
        this.task = {
            ...this.taskData,
            description: this.taskData.description || '',
        };

        if (this.task.assignee) {
            this.task.assignee = this.users.find(x => x.id === this.task.assignee.id);
        }

        if (!this.task.created_meta) {
            this.task.created_meta = this.me;
        }
        this.initialData = { ...this.task };

        this.taskStatusOptions = this.config.dispo.task_statuses;

        this.filteredTaskStatusOptions = this.$getPossibleTaskStatusesToUpdateTaskTo(this.task);

        this.task.type = this.task.type.id ? this.taskTypes.find((data) => data.id === this.task.type.id) : null;
        this.loadProjects();
        this.loadLogs();

        if (this.isNewTask) {
            this.populateSelections();
        }

        window.addEventListener('keyup', this.handleEscapeKeyPress);
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleEscapeKeyPress);
        this.$store.commit('tasks/resetTask');
    },
    methods: {
        updateSendMessage(state, entityId, type, recipient, projectID) {
            this.$store.dispatch('messages/setShowNewMessage', { state, entityId, type, recipient, projectID });
        },
        handleEscapeKeyPress(key) {
            if (key.code === 'Escape') {
                this.closeTask();
            }
        },
        handleChange(type) {
            switch (type) {
                case 'type':
                case 'project':
                    if (this.selections.project && this.selections.type?.id) {
                        switch (this.selections.type.id) {
                            case 3: this.loadBids(this.selections.project.id); break;
                            case 4: this.loadResourceTypes(this.selections.project.id); break;
                            case 6: this.loadDailies(this.selections.project.id); break;
                            case 7: this.loadBroadcasts(this.selections.project.id); break;
                            case 5:
                            case 8: this.loadSequences(this.selections.project.id); break;
                            default: break;
                        }
                    }
                    this.sortedUsers = this.generateSortedUsers();
                    break;
                case 'resourceType':
                    if (this.selections.resourceType) {
                        this.loadResources(this.selections.project.id, this.selections.resourceType.id);
                    }
                    break;
                case 'sequence':
                    if (this.selections.sequence) {
                        this.loadShots(this.selections.sequence.id);
                    }
                    break;
                case 'shot':
                case 'passType':
                    if (this.selections.shot && this.selections.passType) {
                        this.loadPasses(this.selections.shot.id, this.selections.passType);
                    }
                    break;
                case 'pass':
                    if (this.selections.pass) {
                        this.loadSubmits(this.selections.pass.id);
                    }
                    break;
            }
        },
        loadLogs() {
            this.$axios
            .$get(`${process.env.prodAPI}/tasklog/${this.task.id}`)
            .then((res) => {
              this.logs = res.result;
            })
            .catch((err) => {
              this.$notify({ title: err.response.data.message, type: 'error' });
            });
        },
        loadProjects() {
            this.loading.project = true;
            this.$axios
                .$get(`${process.env.prodAPI}/projects?order=name&scopes[]=with_subscribed_users&filters[]=overLimit:eq:true&filters[]=status:neq:archived`)
                .then((res) => {
                    this.entities.projects = res.result;
                })
                .catch(this.$catchPost)
                .finally(() => {
                    this.$axios
                        .$get(`${process.env.prodAPI}/projects?order=name&scopes[]=with_subscribed_users&filters[]=overLimit:eq:true&filters[]=status:eq:archived`)
                        .then((res) => {
                            this.entities.projects = this.entities.projects.concat(res.result);
                            if (this.selections.project) {
                                this.selections.project = this.entities.projects.find((pr) => pr.id === this.selections.project.id);
                                this.sortedUsers = this.generateSortedUsers();
                            }
                            if (this.$refs.observer) {
                                this.$refs.observer.reset();
                            }
                        })
                        .catch(this.$catchPost)
                        .finally(() => {
                            this.loading.project = false;
                        });
                });
        },
        loadBids(project) {
            if (!project) return;

            this.loading.bid = true;
            this.$axios
                .$get(`${process.env.prodAPI}/bids?order=name&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.bids = res.result;
                    if (this.selections.bid) {
                        this.selections.bid = res.result.find((b) => b.id === this.selections.bid.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.bid = false;
                });
        },
        loadResourceTypes(project) {
            if (!project) return;

            this.loading.resourceType = true;
            this.$axios
                .$get(`${process.env.prodAPI}/resource-categories?order=name&filters[]=project:eq:${project}:or&filters[]=project:n::or`)
                .then((res) => {
                    this.entities.resourceTypes = res.result;
                    if (this.selections.resourceType) {
                        this.selections.resourceType = res.result.find((rt) => rt.id === this.selections.resourceType.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.resourceType = false;
                });
        },
        async loadResources(project, type, id = null) {
            if (!project || (!type && !id)) return;

            let resource = null;
            if (id) {
                resource = (await this.$axios
                    .$get(`${process.env.prodAPI}/resources/${id}`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                type = resource.category.id;
                this.selections.resourceType = { id: type };
                this.loadResourceTypes(this.selections.project.id);
            }

            this.loading.resource = true;
            this.$axios
                .$get(`${process.env.prodAPI}/resources?order=-version&filters[]=project:eq:${project}&filters[]=category:eq:${type}`)
                .then((res) => {
                    this.entities.resources = res.result;
                    const toSearch = resource?.id ? resource.id : this.selections?.resource?.id;
                    if (toSearch) {
                        this.selections.resource = res.result.find((r) => r.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.resource = false;
                });
        },
        loadSequences(project) {
            if (!project) return;

            this.loading.sequence = true;
            this.$axios
                .$get(`${process.env.prodAPI}/sequences?order=name&filters[]=overLimit:eq:true&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.sequences = res.result;
                    if (this.selections.sequence) {
                        this.selections.sequence = res.result.find((se) => se.id === this.selections.sequence.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.sequence = false;
                });
        },
        async loadShots(sequence, id = null) {
            if (!sequence && !id) return;

            let shot = null;
            if (id) {
                shot = (await this.$axios
                    .$get(`${process.env.prodAPI}/shots/${id}?scopes[]=with_sequence`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                sequence = shot.sequence.id;
                this.selections.sequence = { id: sequence };
                this.loadSequences(this.selections.project.id);
            }

            this.loading.shot = true;
            this.$axios
                .$get(`${process.env.prodAPI}/shots?order=name&filters[]=overLimit:eq:true&filters[]=sequence:eq:${sequence}`)
                .then((res) => {
                    this.entities.shots = res.result;
                    const toSearch = shot?.id ? shot.id : this.selections?.shot?.id;
                    if (toSearch) {
                        this.selections.shot = res.result.find((sh) => sh.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.shot = false;
                });
        },
        loadPasses(shot, type) {
            if (!shot || !type) return;

            this.loading.pass = true;
            this.$axios
                .$get(`${process.env.prodAPI}/passes?order=name&filters[]=overLimit:eq:true&filters[]=shot:eq:${shot}&filters[]=type:lk:${type}`)
                .then((res) => {
                    this.entities.passes = res.result;
                    if (this.selections.pass) {
                        this.selections.pass = res.result.find((pa) => pa.id === this.selections.pass.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.pass = false;
                });
        },
        async loadSubmits(pass, id = null) {
            if (!pass && !id) return;

            let submit = null;
            if (id) {
                submit = (await this.$axios
                    .$get(`${process.env.prodAPI}/submits/${id}?scopes[]=with_pass&scopes[]=with_shot&scopes[]=with_sequence`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                pass = submit.pass.id;
                this.selections.passType = submit.pass.type;
                this.selections.pass = { id: pass, name: submit.pass.name };
                this.selections.shot = { id: submit.pass.shot.id, name: submit.pass.shot.name };
                this.selections.sequence = { id: submit.pass.shot.sequence.id, name: submit.pass.shot.sequence.name };
                this.loadPasses(submit.pass.shot.id, submit.pass.type);
                await this.loadShots(submit.pass.shot.sequence.id);
                this.loadSequences(this.selections.project.id);
            }

            this.loading.submit = true;
            this.$axios
                .$get(`${process.env.prodAPI}/submits?order=-version&filters[]=overLimit:eq:true&filters[]=pass:eq:${pass}`)
                .then((res) => {
                    this.entities.submits = res.result;
                    const toSearch = submit?.id ? submit.id : this.selections?.submit?.id;
                    if (toSearch) {
                        this.selections.submit = res.result.find((su) => su.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.submit = false;
                });
        },
        loadDailies(project) {
            if (!project) return;

            this.loading.daily = true;
            this.$axios
                .$get(`${process.env.prodAPI}/dailies?order=pass.name&filters[]=overLimit:eq:true&scopes[]=with_pass&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.dailies = res.result;
                    if (this.selections.daily) {
                        this.selections.daily = res.result.find((s) => s.id === this.selections.daily.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.daily = false;
                });
        },
        loadBroadcasts(project) {
            if (!project) return;

            this.loading.broadcast = true;
            this.$axios
                .$get(`${process.env.prodAPI}/broadcasts?order=pass.name&filters[]=overLimit:eq:true&scopes[]=with_pass&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.broadcasts = res.result;
                    if (this.selections.broadcast) {
                        this.selections.broadcast = res.result.find((s) => s.id === this.selections.broadcast.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.broadcast = false;
                });
        },
        closeTask() {
            if (this.isEditModeActive === false) {
                this.task.name = '';
                this.task.dateStart = '';
                this.task.dateEnd = '';
                this.task.description = '';
                this.task.descriptionsummary = '';

                this.$store.commit('tasks/resetTask');
                this.$emit('updateTaskVisibility', false);
            }
            this.isEditModeActive = false;
        },
        onSave(taskId, commentEnable = false, status = null) {
            if (this.isNewTask) {
                this.addTask();
            } else if(commentEnable) {
                this.taskId = taskId;
                this.prevStatus = {...this.task.status};
                this.task.status = status;
                this.saveWithComment = true;
            } else {
                this.editTask(taskId);
            }
            this.isEditModeActive = false;
        },
        generatePostData() {
            const postData = new FormData();

            if (this.getModalViewType().id === 'view' && this.getModalViewType().target !== 'admin') {
                if (this.task.status && this.task.status.id) {
                    postData.append('status', this.task.status.id);
                }
                return postData;
            }

            if (this.task.name) {
                postData.append('name', this.task.name);
            }
            if (this.task.status && typeof this.task.status.id === 'number') {
                postData.append('status', this.task.status.id);
            } else {
                postData.append('status', '1');
            }
            if (this.isEditModeActive || this.isNewTask) {
                postData.append('type', this.selections.type.id);
            } else if (this.task.type) {
                postData.append('type', this.task.type.id);
            }
            if (this.isEditModeActive || this.isNewTask) {
                switch (this.selections.type.id) {
                    case 2:
                        postData.append('entityId', this.selections.project.id);
                        break;
                    case 3:
                        postData.append('entityId', this.selections.bid.id);
                        break;
                    case 4:
                        postData.append('entityId', this.selections.resource.id);
                        break;
                    case 5:
                        postData.append('entityId', this.selections.shot.id);
                        break;
                    case 6:
                        postData.append('entityId', this.selections.daily.id);
                        break;
                    case 7:
                        postData.append('entityId', this.selections.broadcast.id);
                        break;
                    case 8:
                        postData.append('entityId', this.selections.submit.id);
                        break;
                }
            } else if (this.task.entity) {
                postData.append('entityId', this.task.entity.id);
            }
            if (this.task.dateStart) {
                postData.append('dateStart', this.task.dateStart);
            }
            if (this.task.dateEnd) {
                postData.append('dateEnd', this.task.dateEnd);
            }
            if (this.task.description) {
                postData.append('description', this.task.description);
            } else {
                postData.append('description', '');
            }
            if (this.task.summary) {
                postData.append('summary', this.task.summary);
            } else {
                postData.append('summary', '');
            }
            if (this.task.assignee) {
                postData.append('assignee', String(this.task.assignee.id));
            } else {
                postData.append('assignee', '');
            }
            if (this.task.created_meta) {
                postData.append('created_by', String(this.task.created_meta.id));
            } else {
                postData.append('created_by', '');
            }
            if (this.task.usersToNotify && this.task.usersToNotify.length > 0) {
                for (const user of this.task.usersToNotify) {
                    postData.append('usersToNotify[]', user.id);
                }
            } else {
                postData.append('usersToNotify[]', '');
            }
            return postData;
        },
        addTask() {
            this.postInProgress = true;
            this.$axios
                .$post(`${process.env.prodAPI}/tasks`, this.generatePostData())
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.$nuxt.$emit('updateDispoAssignTaskModalWithJustCreatedTaskData', res.result);
                    this.closeTask();
                    this.$nuxt.$emit('refreshTasksTable');
                })
                .catch((err) => {
                    this.$refs.observer.setErrors(this.$formatErrorMessages(err.response.data.errors));
                })
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        cancelStatusUpdate() {
            this.saveWithComment = false;
            this.task.status = this.filteredTaskStatusOptions.find(x => x.id === this.prevStatus.id);
        },
        editTask(taskId) {
            this.saveWithComment = false;
            this.postInProgress = true;
            this.$axios
                .$post(`${process.env.prodAPI}/tasks/${taskId}`, this.generatePostData())
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.initialData = { ...this.task };
                    this.difference = null;
                    this.$nuxt.$emit('refreshTasksTable');
                    this.closeTask();
                })
                .catch(this.$catchPost)
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        generateSortedUsers() {
            const id = this.selections.project?.id
                ? this.selections.project.id
                : this.task?.project?.id
                    ? this.task?.project?.id
                    : this.taskData?.project?.id
                        ? this.taskData?.project?.id
                        : null;
            const _project = this.entities?.projects?.find((x) => x.id === id);

            if (_project?.subscribedUsers) {
                const teamIDs = _project.subscribedUsers.map((x) => x.user.id);
                const first = [];
                const second = [];
                for (const user of this.users) {
                    user.hasLine = false;
                    if (teamIDs.includes(user.id)) {
                        first.push(user);
                    } else {
                        second.push(user);
                    }
                }
                first[first.length - 1].hasLine = true;
                return [...first, ...second];
            }

            for (const user of this.users) {
                user.hasLine = false;
            }
            return [...this.users];
        },
        getModalViewType() {
            if (this.isNewTask) {
                return {
                    id: 'edit'
                }
            }

            if (
                this.$user.id === this.task?.created_meta?.id ||
                this.$user.roles.includes('ROLE_ADMIN') ||
                this.$user.roles.includes('ROLE_SUPERVISOR') ||
                this.$user.roles.includes('ROLE_SUB_SUPERVISOR') ||
                this.$user.roles.includes('ROLE_PRODUCER') ||
                this.$user.roles.includes('ROLE_SUB_PRODUCER')
            ) {
                return this.isEditModeActive || this.isNewTask
                    ? {
                        id: 'edit',
                    } : {
                        id: 'view',
                        target: 'admin',
                    }
            }

            return {
                id: 'view'
            }
        },
        goToUrlAndCloseModal(url) {
            this.$router.push(url);
            document.querySelector("html").style = {};
        },
        handleTitleLinkClick() {
            if (this.task && this.task.type && this.task.type.id) {
                switch (this.task.type.id) {
                    case 2:
                        if (this.task.project && this.task.project.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}`);
                        }
                        break;
                    case 3:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('broadcast.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/details/bids`);
                            } else if (!this.$user.roles.includes('ROLE_ARTIST')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/details`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}`);
                            }
                        }
                        break;
                    case 4:
                        if (this.task.project && this.task.project.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/` +
                                `${this.projectStatus(this.task.project.status, 'work')}/resources/${this.task.subject.id}`);
                        }
                        break;
                    case 5:
                        if (this.task.project && this.task.project.id && this.task.subject && this.task.subject.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/shots/${ this.task.subject.id }`);
                        }
                        break;
                    case 6:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('daily.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/dailies`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work`);
                            }
                        }
                        break;
                    case 7:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('broadcast.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/broadcast`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work`);
                            }
                        }
                        break;
                    case 8:
                        if (this.task.project && this.task.project.id && this.task.subject && this.task.subject.id && this.task.subject.shot && this.task.subject.shot.id && this.task.subject.passType) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/shots/${this.task.subject.shot.id}/${this.task.subject.passType.toLowerCase()}/${this.task.subject.id}`);
                        }
                        break;
                    default:
                        break;
                }
            }
        },
        async populateSelections() {
            if (!this.taskData?.type?.id) {
                return;
            }
            this.selections.type = this.taskTypes.find(t => t.id === this.taskData.type.id);
            if (this.taskData?.project?.id || this.taskData.type.id === 2) {
                const project = this.taskData?.project?.id
                    ? this.taskData.project
                    : this.taskData?.entity?.id
                        ? { id: parseInt(this.taskData.entity.id) }
                        : null;
                if (!project) return;
                project.id = parseInt(project.id);
                if (this.entities?.projects?.length === 0) {
                    this.selections.project = project;
                } else {
                    this.selections.project = this.entities.projects.find(p => p.id === project.id);
                    this.sortedUsers = this.generateSortedUsers();
                }
            } else if (this.taskData?.entity?.id) {
                let endpoint = '';
                const params = new URLSearchParams();
                switch (this.taskData.type.id) {
                    case 3: endpoint = 'bids'; break;
                    case 4: endpoint = 'resources'; break;
                    case 5:
                        endpoint = 'shots';
                        params.append('scopes[]', 'with_sequence');
                        params.append('scopes[]', 'with_project');
                        break;
                    case 6: endpoint = 'dailies'; break;
                    case 7: endpoint = 'broadcasts'; break;
                    case 8:
                        endpoint = 'submits';
                        params.append('scopes[]', 'with_pass');
                        params.append('scopes[]', 'with_shot');
                        params.append('scopes[]', 'with_sequence');
                        params.append('scopes[]', 'with_project');
                        break;
                }
                const result = (
                    await this.$axios
                        .$get(
                            `${process.env.prodAPI}/${endpoint}/${this.taskData.entity.id}`,
                            { params }
                        )
                ).result;
                let id;
                switch (this.taskData.type.id) {
                    case 3:
                    case 4:
                    case 6:
                    case 7:
                        id = result.project.id;
                        break;
                    case 5:
                        id = result.sequence.project.id;
                        break;
                    case 8:
                        id = result.pass.shot.sequence.project.id;
                        break;
                }
                const project = { id };
                if (this.entities?.projects?.length === 0) {
                    this.selections.project = project;
                } else {
                    this.selections.project = this.entities.projects.find(p => p.id === project.id);
                    this.sortedUsers = this.generateSortedUsers();
                }
            } else {
                return;
            }
            const idObj = { id: this.taskData.entity.id };
            switch (this.taskData.type.id) {
                case 2: // Project
                    break;
                case 3: // Bid
                    this.selections.bid = idObj;
                    this.loadBids(this.selections.project.id);
                    break;
                case 4: // Resource
                    await this.loadResources(this.selections.project.id, null, this.taskData.entity.id);
                    break;
                case 5: // Shot
                    await this.loadShots(null, this.taskData.entity.id);
                    break;
                case 6: // Daily
                    this.selections.daily = idObj;
                    this.loadDailies(this.selections.project.id);
                    break;
                case 7: // Broadcast
                    this.selections.broadcast = idObj;
                    this.loadBroadcasts(this.selections.project.id);
                    break;
                case 8: // Submit
                    await this.loadSubmits(null, this.taskData.entity.id);
                    break;
            }
        },
        projectStatus(status, detailsOrWork) {
            switch (status) {
                case 'preproduction':
                case 'on_hold_pre':
                    return 'preproduction';
                default:
                    return `${detailsOrWork}`;
            }
        },
    },
};
</script>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>

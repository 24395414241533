<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="540"
        persistent
    >
        <div class="dialog-body">
            <modal-header text="Send message" icon="la-paper-plane" @closeModal="closeModal" />

            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="onSave">
                    {{/* Recipients */}}
                    <div class="mb-4 flex flex-column gap-2">
                        <label class="form-label mb-0">To*</label>
                        <validation-provider v-slot="{ errors }" vid="recipients" name="Assignee">
                            <v-autocomplete
                                v-if="users && me"
                                v-model="message.assigned_to"
                                name="assigned_to"
                                :items="sortedUsers"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :error-messages="errors"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                label="Send message to"
                                multiple
                                solo
                                dense
                                chips
                                return-object
                                deletable-chips
                            >
                                <template #selection="datas">
                                    <v-chip
                                        v-bind="datas.attrs"
                                        :input-value="datas.selected"
                                        close
                                        @click="datas.select"
                                        @click:close="removeRecipient(datas.item)"
                                    >
                                        <user-badge :user="datas.item" is-small full-name />
                                    </v-chip>
                                </template>
                                <template #item="{ item }">
                                    <div class="w-full">
                                        <user-badge :user="item" full-name />
                                        <div v-if="item.hasLine" class="border-b border-gray-150 mt-2" />
                                    </div>
                                </template>
                            </v-autocomplete>
                        </validation-provider>

                        <label class="form-label mb-0">Cc</label>
                        <validation-provider v-slot="{ errors }" vid="recipients" name="Assignee">
                            <v-autocomplete
                                v-if="users && me"
                                v-model="message.carbon_copy"
                                name="assigned_to"
                                :items="sortedUsers"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :error-messages="errors"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                label="Send message to"
                                multiple
                                solo
                                dense
                                chips
                                return-object
                                deletable-chips
                            >
                                <template #selection="datas">
                                    <v-chip
                                        v-bind="datas.attrs"
                                        :input-value="datas.selected"
                                        close
                                        @click="datas.select"
                                        @click:close="removeCarbonCopy(datas.item)"
                                    >
                                        <user-badge :user="datas.item" is-small full-name />
                                    </v-chip>
                                </template>
                                <template #item="{ item }">
                                    <div class="w-full">
                                        <user-badge :user="item" full-name />
                                        <div v-if="item.hasLine" class="border-b border-gray-150 mt-2" />
                                    </div>
                                </template>
                            </v-autocomplete>
                        </validation-provider>

                        <base-switch
                            label="Send emails"
                            name="send_emails_switch"
                            :value="message.sendEmails"
                            @input="(val) => { message.sendEmails = val; }"
                        />
                    </div>

                    {{/* Selections */}}
                    <div class="col-span-6 grid grid-cols-8 gap-y-6 gap-x-4 mb-4">
                        {{/* Type */}}
                        <div class="col-span-4">
                            <label class="form-label">Type*</label>
                            <validation-provider v-slot="{ errors }" vid="entityId" name="Type">
                                <v-select
                                    v-model="selections.type"
                                    solo
                                    dense
                                    return-object
                                    required
                                    :items="messageTypes"
                                    :item-text="(item) => item.label"
                                    :error-messages="errors"
                                    @input="handleChange('type')"
                                />
                            </validation-provider>
                        </div>

                        {{/* Project */}}
                        <div v-if="selections.type.id > 1" class="col-span-4">
                            <label class="form-label">Project*</label>
                            <validation-provider v-slot="{ errors }" name="Project">
                                <v-autocomplete
                                    v-model="selections.project"
                                    name="Project"
                                    :items="entities.projects"
                                    :item-text="(item) => item.name"
                                    :error-messages="errors"
                                    :menu-props="{ closeOnContentClick: true }"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    @input="handleChange('project')"
                                >
                                    <template #selection="datas">
                                        <project-badge
                                            v-if="!loading.project"
                                            :project="datas.item"
                                            is-small
                                            truncate
                                            @click="datas.select"
                                        />
                                    </template>
                                    <template #item="{ item }">
                                        <project-badge :project="item" is-small />
                                    </template>
                                </v-autocomplete>
                            </validation-provider>
                            <transition name="fade">
                                <div
                                    v-if="loading.project"
                                    class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                >
                                    <i class="animate-spin las la-circle-notch text-xl" />
                                </div>
                            </transition>
                        </div>

                        {{/* Bid */}}
                        <div v-if="selections.type.id === 3" class="col-span-8">
                            <validation-provider v-slot="{ errors }" name="Bid">
                                <label class="form-label">Bid*</label>
                                <v-select
                                    v-model="selections.bid"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.bids"
                                    :error-messages="errors"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.bid" class="selection-wrap v-select__selection--comma">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.bid"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* ResourceType */}}
                        <div v-if="selections.type.id === 4" class="col-span-6">
                            <validation-provider v-slot="{ errors }" name="Resource Type">
                                <label class="form-label">Resource type*</label>
                                <v-select
                                    v-model="selections.resourceType"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.resourceTypes"
                                    :error-messages="errors"
                                    @input="handleChange('resourceType')"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.resourceType" class="selection-wrap v-select__selection--comma">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.resourceType"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Resource (version) */}}
                        <div v-if="selections.type.id === 4" class="col-span-2">
                            <validation-provider v-slot="{ errors }" name="Resource">
                                <label class="form-label">Version*</label>
                                <v-select
                                    v-model="selections.resource"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.resources"
                                    :error-messages="errors"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.resource" class="v-select__selection--comma">{{ `v${item.version}` }}</span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ `v${item.version}` }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.resource"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Daily */}}
                        <div v-if="selections.type.id === 6" class="col-span-8">
                            <validation-provider v-slot="{ errors }" name="Daily">
                                <label class="form-label">Daily*</label>
                                <v-select
                                    v-model="selections.daily"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.dailies"
                                    :error-messages="errors"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.daily" class="selection-wrap v-select__selection--comma">
                                            {{ `${item.submit.pass.name} v${item.clientVersion} (${item.preset}) #${item.id}` }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ `${item.submit.pass.name} v${item.clientVersion} (${item.preset}) #${item.id}` }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.daily"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Broadcast */}}
                        <div v-if="selections.type.id === 7" class="col-span-8">
                            <validation-provider v-slot="{ errors }" name="Broadcast">
                                <label class="form-label">Broadcast*</label>
                                <v-select
                                    v-model="selections.broadcast"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.broadcasts"
                                    :error-messages="errors"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.broadcast" class="selection-wrap v-select__selection--comma">
                                            {{ `${item.submit.pass.name} - ${item.filmCode} (${item.preset.name}) #${item.id}` }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ `${item.submit.pass.name} - ${item.filmCode} (${item.preset.name}) #${item.id}` }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.broadcast"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Group */}}
                        <div v-if="[5,8].includes(selections.type.id)" class="col-span-4">
                            <validation-provider v-slot="{ errors }" name="Sequence">
                                <label class="form-label">Group*</label>
                                <v-select
                                    v-model="selections.sequence"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.sequences"
                                    :error-messages="errors"
                                    @input="handleChange('sequence')"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.sequence" class="selection-wrap v-select__selection--comma">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.sequence"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Shot */}}
                        <div v-if="[5,8].includes(selections.type.id)" class="col-span-4">
                            <validation-provider v-slot="{ errors }" name="Shot">
                                <label class="form-label">Shot*</label>
                                <v-select
                                    v-model="selections.shot"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.shots"
                                    :error-messages="errors"
                                    @input="handleChange('shot')"
                                >
                                    <template #selection="{ item }">
                                        <span class="selection-wrap v-select__selection--comma">{{ item.name }}</span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.shot"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Pass type */}}
                        <div v-if="selections.type.id === 8" class="col-span-2">
                            <validation-provider v-slot="{ errors }" name="Pass type">
                                <label class="form-label">Pass type*</label>
                                <v-select
                                    v-model="selections.passType"
                                    solo
                                    dense
                                    return-object
                                    :items="entities.passTypes"
                                    :error-messages="errors"
                                    @input="handleChange('passType')"
                                >
                                    <template #selection="{ item }">
                                        <span class="v-select__selection--comma">
                                            {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.charAt(0).toUpperCase() + item.slice(1) }}
                                    </template>
                                </v-select>
                            </validation-provider>
                        </div>

                        {{/* Pass */}}
                        <div v-if="selections.type.id === 8" class="col-span-4">
                            <validation-provider v-slot="{ errors }" name="Pass">
                                <label class="form-label">Pass*</label>
                                <v-select
                                    v-model="selections.pass"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.passes"
                                    :error-messages="errors"
                                    @input="handleChange('pass')"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.pass" class="selection-wrap v-select__selection--comma">
                                            {{ item.name }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.pass"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Submit */}}
                        <div v-if="selections.type.id === 8" class="col-span-2">
                            <validation-provider v-slot="{ errors }" name="Submit">
                                <label class="form-label">Version*</label>
                                <v-select
                                    v-model="selections.submit"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="entities.submits"
                                    :error-messages="errors"
                                >
                                    <template #selection="{ item }">
                                        <span v-if="!loading.submit" class="v-select__selection--comma">
                                            {{ `v${item.version}` }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ `v${item.version}` }}
                                    </template>
                                </v-select>
                                <transition name="fade">
                                    <div
                                        v-if="loading.submit"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>

                        {{/* Task type */}}
                        <div v-if="selections.type.id === 9" class="col-span-2">
                            <validation-provider v-slot="{ errors }" name="Task type">
                                <label class="form-label">Task type</label>
                                <v-select
                                    v-model="selections.taskType"
                                    solo
                                    dense
                                    return-object
                                    required
                                    :items="taskTypes"
                                    :item-text="(item) => item.label"
                                    :error-messages="errors"
                                    @input="handleChange('taskType')"
                                />
                            </validation-provider>
                        </div>

                        {{/* Task */}}
                        <div v-if="selections.type.id === 9" class="col-span-6">
                            <validation-provider v-slot="{ errors }" name="Task">
                                <label class="form-label">Task*</label>
                                <v-autocomplete
                                    v-model="selections.task"
                                    name="Task"
                                    :items="entities.tasks"
                                    :item-text="(item) => item.name"
                                    :error-messages="errors"
                                    :menu-props="{ closeOnContentClick: true }"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                >
                                    <template #selection="datas">
                                        <span
                                            v-if="!loading.task"
                                            class="selection-wrap v-select__selection--comma"
                                            @click="datas.select"
                                        >
                                            {{ datas.item.name }}
                                        </span>
                                    </template>
                                    <template #item="{ item }">
                                        {{ item.name }}
                                    </template>
                                </v-autocomplete>
                                <transition name="fade">
                                    <div
                                        v-if="loading.task"
                                        class="relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"
                                    >
                                        <i class="animate-spin las la-circle-notch text-xl" />
                                    </div>
                                </transition>
                            </validation-provider>
                        </div>
                    </div>

                    {{/* Message */}}
                    <div class="mb-4">
                        <label class="form-label">Message*</label>
                        <validation-provider v-slot="{ errors }" vid="payload" name="Message">
                            <ck-editor
                                v-if="showEditor"
                                v-model="message.message"
                                slim
                                set-focus
                                :error-messages="errors"
                            />
                            <div v-else class="flex justify-center items-center h-20">
                                <i class="animate-spin las la-circle-notch" style="font-size: 2rem !important;" />
                            </div>
                        </validation-provider>
                    </div>

                    <footer class="dialog-footer">
                        <div class="col-span-2">
                            <v-btn block @click="closeModal">Cancel</v-btn>
                        </div>
                        <div class="col-start-3 col-span-3">
                            <v-btn
                                block
                                color="primary"
                                :disabled="invalid || !canSend"
                                type="submit"
                            >
                                <i class="las la-paper-plane mr-2" />
                                Send
                            </v-btn>
                        </div>
                    </footer>
                </v-form>
            </validation-observer>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        showModal: { type: Boolean, required: false },
    },
    data() {
        return {
            showEditor: false,
            message: {
                message: '',
                assigned_to: [],
                carbon_copy: [],
                sendEmails: true,
            },
            sortedUsers: [],
            postInProgress: false,
            selections: {
                type: { id: 1, label: 'General' },
                project: null,
                sequence: null,
                shot: null,
                passType: 'CG',
                pass: null,
                submit: null,
                bid: null,
                resourceType: null,
                resource: null,
                daily: null,
                broadcast: null,
                taskType: { id: 0, label: 'All' },
                task: null,
            },
            entities: {
                projects: [],
                sequences: [],
                shots: [],
                passTypes: ['CG', 'Comp', 'Image', 'Audio'],
                passes: [],
                submits: [],
                bids: [],
                resourceTypes: [],
                resources: [],
                dailies: [],
                broadcasts: [],
                tasks: [],
            },
            loading: {
                project: false,
                sequence: false,
                shot: false,
                pass: false,
                submit: false,
                bid: false,
                resourceType: false,
                resource: false,
                daily: false,
                broadcast: false,
                task: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
            users: 'config/getUsers',
            me: 'users/me/getMe',
            messageType: 'messages/getMessageType',
            messageEntityID: 'messages/getMessageEntityID',
            messageProject: 'messages/getMessageProject',
            messageRecipient: 'messages/getMessageRecipient',
            messagePayload: 'messages/getMessagePayload',
        }),
        dialog: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.$store.commit('messages/setShowNewMessage', value);
                this.$refs.observer.reset();
            },
        },
        messageTypes() {
            return this.$getMessageTypesFilteredByUserPermission();
        },
        taskTypes() {
            return [
                { id: 0, label: 'All' },
                ...this.$getTaskTypesFilteredByUserPermission(),
            ];
        },
        canSend() {
            if (this.postInProgress || !this.message.message || !this.message.assigned_to.length) return false;
            for (const key of Object.keys(this.loading)) {
                if (this.loading[key]) {
                    return false;
                }
            }
            switch (this.selections.type.id) {
                case 1: // General
                    return true;
                case 2: // Project
                    return !!this.selections.project;
                case 3: // Bid
                    return !!this.selections.bid;
                case 4: // Resource
                    return !!this.selections.resource;
                case 5: // Shot
                    return !!this.selections.shot;
                case 6: // Daily
                    return !!this.selections.daily;
                case 7: // Broadcast
                    return !!this.selections.broadcast;
                case 8: // Submit
                    return !!this.selections.submit;
                case 9:
                    return !!this.selections.task;
                default:
                    return false;
            }
        }
    },
    watch: {
        showModal(isOpen) {
            if (isOpen === true) {
                if (this.messageRecipient) {
                    this.message.assigned_to.push(this.users.find((data) => data.id === this.messageRecipient));
                }
                this.loadProjects();

                this.populateSelections();
                this.sortedUsers = this.generateSortedUsers();

                setTimeout(() => {
                    this.showEditor = true;
                    this.message.message = this.messagePayload;
                }, 500);
            } else {
                setTimeout(() => {
                    this.selections.type = { id: 1, label: 'General' };
                    this.selections.taskType = { id: 0, label: 'All' };
                    this.selections.passType = 'CG';
                    for (const key of Object.keys(this.selections)) {
                        if (!['type', 'taskType', 'passType'].includes(key)) {
                            this.selections[key] = null;
                        }
                    }
                    for (const key of Object.keys(this.entities)) {
                        if (!['passTypes'].includes(key)) {
                            this.entities[key] = [];
                        }
                    }
                    this.message.message = '';
                    this.message.assigned_to = [];
                    this.message.carbon_copy = [];
                    this.message.sendEmails = true;
                    this.$store.commit('messages/setMessageEntityID', null);
                    this.$store.commit('messages/setMessageType', null);
                    this.$store.commit('messages/setMessageRecipient', null);
                    this.$refs.observer.reset();
                    this.showEditor = false;
                }, 500);
            }
        },
        'message.message'(e) {
            if (e?.length >= 3) {
                this.$refs.observer.validate();
            }
        },
    },
    methods: {
        handleChange(type) {
            switch (type) {
                case 'type':
                case 'project':
                    if (this.selections.project && this.selections.type?.id) {
                        switch (this.selections.type.id) {
                            case 3: this.loadBids(this.selections.project.id); break;
                            case 4: this.loadResourceTypes(this.selections.project.id); break;
                            case 6: this.loadDailies(this.selections.project.id); break;
                            case 7: this.loadBroadcasts(this.selections.project.id); break;
                            case 5:
                            case 8: this.loadSequences(this.selections.project.id); break;
                            case 9: this.loadTasks(this.selections.project.id, this.selections.taskType.id); break;
                            default: break;
                        }
                    }
                    this.sortedUsers = this.generateSortedUsers();
                    break;
                case 'resourceType':
                    if (this.selections.resourceType) {
                        this.loadResources(this.selections.project.id, this.selections.resourceType.id);
                    }
                    break;
                case 'sequence':
                    if (this.selections.sequence) {
                        this.loadShots(this.selections.sequence.id);
                    }
                    break;
                case 'shot':
                case 'passType':
                    if (this.selections.shot && this.selections.passType) {
                        this.loadPasses(this.selections.shot.id, this.selections.passType);
                    }
                    break;
                case 'pass':
                    if (this.selections.pass) {
                        this.loadSubmits(this.selections.pass.id);
                    }
                    break;
                case 'taskType':
                    if (this.selections.project && this.selections.taskType) {
                        this.loadTasks(this.selections.project.id, this.selections.taskType.id);
                    }
                    break;
            }
        },
        loadProjects() {
            this.loading.project = true;
            this.$axios
                .$get(`${process.env.prodAPI}/projects?order=name&scopes[]=with_subscribed_users&filters[]=overLimit:eq:true&filters[]=status:neq:archived`)
                .then((res) => {
                    this.entities.projects = res.result;
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.$axios
                        .$get(`${process.env.prodAPI}/projects?order=name&scopes[]=with_subscribed_users&filters[]=overLimit:eq:true&filters[]=status:eq:archived`)
                        .then((res) => {
                            this.entities.projects = this.entities.projects.concat(res.result);
                            if (this.selections.project) {
                                this.selections.project = this.entities.projects.find((pr) => pr.id === this.selections.project.id);
                                this.sortedUsers = this.generateSortedUsers();
                            }
                            if (this.$refs.observer) {
                                this.$refs.observer.reset();
                            }
                        })
                        .catch((err) => {
                            this.$notify({ title: err.response.data.message, type: 'error' });
                        })
                        .finally(() => {
                            this.loading.project = false;
                        });
                });
        },
        loadBids(project) {
            if (!project) return;

            this.loading.bid = true;
            this.$axios
                .$get(`${process.env.prodAPI}/bids?order=name&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.bids = res.result;
                    if (this.selections.bid) {
                        this.selections.bid = res.result.find((b) => b.id === this.selections.bid.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.bid = false;
                });
        },
        loadResourceTypes(project) {
            if (!project) return;

            this.loading.resourceType = true;
            this.$axios
                .$get(`${process.env.prodAPI}/resource-categories?order=name&filters[]=project:eq:${project}:or&filters[]=project:n::or`)
                .then((res) => {
                    this.entities.resourceTypes = res.result;
                    if (this.selections.resourceType) {
                        this.selections.resourceType = res.result.find((rt) => rt.id === this.selections.resourceType.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.resourceType = false;
                });
        },
        async loadResources(project, type, id = null) {
            if (!project || (!type && !id)) return;

            let resource = null;
            if (id) {
                resource = (await this.$axios
                    .$get(`${process.env.prodAPI}/resources/${id}`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                type = resource.category.id;
                this.selections.resourceType = { id: type };
                this.loadResourceTypes(this.selections.project.id);
            }

            this.loading.resource = true;
            this.$axios
                .$get(`${process.env.prodAPI}/resources?order=-version&filters[]=project:eq:${project}&filters[]=category:eq:${type}`)
                .then((res) => {
                    this.entities.resources = res.result;
                    const toSearch = resource?.id ? resource.id : this.selections?.resource?.id;
                    if (toSearch) {
                        this.selections.resource = res.result.find((r) => r.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.resource = false;
                });
        },
        loadSequences(project) {
            if (!project) return;

            this.loading.sequence = true;
            this.$axios
                .$get(`${process.env.prodAPI}/sequences?order=name&filters[]=overLimit:eq:true&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.sequences = res.result;
                    if (this.selections.sequence) {
                        this.selections.sequence = res.result.find((se) => se.id === this.selections.sequence.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.sequence = false;
                });
        },
        async loadShots(sequence, id = null) {
            if (!sequence && !id) return;

            let shot = null;
            if (id) {
                shot = (await this.$axios
                    .$get(`${process.env.prodAPI}/shots/${id}?scopes[]=with_sequence`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                sequence = shot.sequence.id;
                this.selections.sequence = { id: sequence };
                this.loadSequences(this.selections.project.id);
            }

            this.loading.shot = true;
            this.$axios
                .$get(`${process.env.prodAPI}/shots?order=name&filters[]=overLimit:eq:true&filters[]=sequence:eq:${sequence}`)
                .then((res) => {
                    this.entities.shots = res.result;
                    const toSearch = shot?.id ? shot.id : this.selections?.shot?.id;
                    if (toSearch) {
                        this.selections.shot = res.result.find((sh) => sh.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.shot = false;
                });
        },
        loadPasses(shot, type) {
            if (!shot || !type) return;

            this.loading.pass = true;
            this.$axios
                .$get(`${process.env.prodAPI}/passes?order=name&filters[]=overLimit:eq:true&filters[]=shot:eq:${shot}&filters[]=type:lk:${type}`)
                .then((res) => {
                    this.entities.passes = res.result;
                    if (this.selections.pass) {
                        this.selections.pass = res.result.find((pa) => pa.id === this.selections.pass.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.pass = false;
                });
        },
        async loadSubmits(pass, id = null) {
            if (!pass && !id) return;

            let submit = null;
            if (id) {
                submit = (await this.$axios
                    .$get(`${process.env.prodAPI}/submits/${id}?scopes[]=with_pass&scopes[]=with_shot&scopes[]=with_sequence`)
                    .catch(e => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    })).result;
                pass = submit.pass.id;
                this.selections.passType = submit.pass.type;
                this.selections.pass = { id: pass, name: submit.pass.name };
                this.selections.shot = { id: submit.pass.shot.id, name: submit.pass.shot.name };
                this.selections.sequence = { id: submit.pass.shot.sequence.id, name: submit.pass.shot.sequence.name };
                this.loadPasses(submit.pass.shot.id, submit.pass.type);
                await this.loadShots(submit.pass.shot.sequence.id);
                this.loadSequences(this.selections.project.id);
            }

            this.loading.submit = true;
            this.$axios
                .$get(`${process.env.prodAPI}/submits?order=-version&filters[]=overLimit:eq:true&filters[]=pass:eq:${pass}`)
                .then((res) => {
                    this.entities.submits = res.result;
                    const toSearch = submit?.id ? submit.id : this.selections?.submit?.id;
                    if (toSearch) {
                        this.selections.submit = res.result.find((su) => su.id === toSearch);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.submit = false;
                });
        },
        loadDailies(project) {
            if (!project) return;

            this.loading.daily = true;
            this.$axios
                .$get(`${process.env.prodAPI}/dailies?order=pass.name&filters[]=overLimit:eq:true&scopes[]=with_pass&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.dailies = res.result;
                    if (this.selections.daily) {
                        this.selections.daily = res.result.find((s) => s.id === this.selections.daily.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.daily = false;
                });
        },
        loadBroadcasts(project) {
            if (!project) return;

            this.loading.broadcast = true;
            this.$axios
                .$get(`${process.env.prodAPI}/broadcasts?order=pass.name&filters[]=overLimit:eq:true&scopes[]=with_pass&filters[]=project:eq:${project}`)
                .then((res) => {
                    this.entities.broadcasts = res.result;
                    if (this.selections.broadcast) {
                        this.selections.broadcast = res.result.find((s) => s.id === this.selections.broadcast.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.broadcast = false;
                });
        },
        loadTasks(project, type) {
            if (!project || type === null) return;

            const params = new URLSearchParams();
            params.append('filters[]', `project:eq:${project}`);
            params.append('filters[]', 'overLimit:eq:true');
            params.append('order', 'name');
            if (type > 0) {
                params.append('filters[]', `type:eq:${type}`);
            }

            this.loading.task = true;
            this.$axios
                .$get(`${process.env.prodAPI}/tasks`, { params })
                .then((res) => {
                    this.entities.tasks = res.result;
                    if (this.selections.task) {
                        this.selections.task = res.result.find((t) => t.id === this.selections.task.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.loading.task = false;
                });
        },
        closeModal() {
            this.$store.commit('messages/setShowNewMessage', false);
        },
        onSave() {
            this.postInProgress = true;
            const postData = new FormData();
            postData.append('type', this.selections.type.id);
            switch (this.selections.type.id) {
                case 2: postData.append('entityId', this.selections.project.id); break;
                case 3: postData.append('entityId', this.selections.bid.id); break;
                case 4: postData.append('entityId', this.selections.resource.id); break;
                case 5: postData.append('entityId', this.selections.shot.id); break;
                case 6: postData.append('entityId', this.selections.daily.id); break;
                case 7: postData.append('entityId', this.selections.broadcast.id); break;
                case 8: postData.append('entityId', this.selections.submit.id); break;
                case 9: postData.append('entityId', this.selections.task.id); break;
            }
            postData.append('payload', this.message.message);
            for (const user of this.message.assigned_to) {
                if (user !== undefined) {
                    postData.append('recipients[]', user.id);
                }
            }
            for (const user of this.message.carbon_copy) {
                if (user !== undefined && !this.message.assigned_to.includes(user)) {
                    postData.append('carbonCopy[]', user.id);
                }
            }
            postData.append('noMail', !this.message.sendEmails);

            this.$axios
                .$post(`${process.env.prodAPI}/messages/send`, postData)
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.$store.commit('messages/setShowNewMessage', false);
                    this.$store.commit('messages/setRefreshMessagesTable', true);
                })
                .catch((err) => {
                    this.$refs.observer.setErrors(this.$formatErrorMessages(err.response.data.errors));
                })
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        removeRecipient(item) {
            this.message.assigned_to = this.message.assigned_to.filter((data) => data && data.id !== item.id);
        },
        removeCarbonCopy(item) {
            this.message.carbon_copy = this.message.carbon_copy.filter((data) => data && data.id !== item.id);
        },
        generateSortedUsers() {
            const id = this.selections?.project?.id
                ? this.selections.project.id
                : this.messageProject
                    ? this.messageProject
                    : null;
            const _project = this.entities?.projects?.find((x) => x.id === id);

            if (_project?.subscribedUsers) {
                const teamIDs = _project.subscribedUsers.map((x) => x.user.id);
                const first = [];
                const second = [];
                for (const user of this.users) {
                    user.hasLine = false;
                    if (teamIDs.includes(user.id)) {
                        first.push(user);
                    } else {
                        second.push(user);
                    }
                }
                first[first.length - 1].hasLine = true;
                return [...first, ...second];
            }

            for (const user of this.users) {
                user.hasLine = false;
            }
            return [...this.users];
        },
        async populateSelections() {
            if (!this.messageType) {
                return;
            }
            this.selections.type = this.messageTypes.find(t => t.id === this.messageType);
            if (this.messageProject || this.messageType === 2) {
                const projectId = this.messageProject
                    ? this.messageProject
                    : this.messageEntityID
                        ? parseInt(this.messageEntityID)
                        : null;
                if (!projectId) return;
                const project = { id: parseInt(projectId) };
                if (this.entities?.projects?.length === 0) {
                    this.selections.project = project;
                } else {
                    this.selections.project = this.entities.projects.find(p => p.id === project.id);
                    this.sortedUsers = this.generateSortedUsers();
                }
            } else if (this.messageEntityID && this.messageType > 2) {
                let endpoint = '';
                const params = new URLSearchParams();
                switch (this.messageType) {
                    case 3: endpoint = 'bids'; break;
                    case 4: endpoint = 'resources'; break;
                    case 5:
                        endpoint = 'shots';
                        params.append('scopes[]', 'with_sequence');
                        params.append('scopes[]', 'with_project');
                        break;
                    case 6: endpoint = 'dailies'; break;
                    case 7: endpoint = 'broadcasts'; break;
                    case 8:
                        endpoint = 'submits';
                        params.append('scopes[]', 'with_pass');
                        params.append('scopes[]', 'with_shot');
                        params.append('scopes[]', 'with_sequence');
                        params.append('scopes[]', 'with_project');
                        break;
                    case 9: endpoint = 'tasks'; break;
                }
                const result = (
                    await this.$axios
                        .$get(
                            `${process.env.prodAPI}/${endpoint}/${this.messageEntityID}`,
                            { params }
                        )
                ).result;
                let id;
                switch (this.messageType) {
                    case 3:
                    case 4:
                    case 6:
                    case 7:
                    case 9:
                        id = result.project.id;
                        break;
                    case 5:
                        id = result.sequence.project.id;
                        break;
                    case 8:
                        id = result.pass.shot.sequence.project.id;
                        break;
                }
                const project = { id };
                if (this.entities?.projects?.length === 0) {
                    this.selections.project = project;
                } else {
                    this.selections.project = this.entities.projects.find(p => p.id === project.id);
                    this.sortedUsers = this.generateSortedUsers();
                }
            } else {
                return;
            }
            const idObj = { id: this.messageEntityID };
            switch (this.messageType) {
                case 2: // Project
                    break;
                case 3: // Bid
                    this.selections.bid = idObj;
                    this.loadBids(this.selections.project.id);
                    break;
                case 4: // Resource
                    await this.loadResources(this.selections.project.id, null, this.messageEntityID);
                    break;
                case 5: // Shot
                    await this.loadShots(null, this.messageEntityID);
                    break;
                case 6: // Daily
                    this.selections.daily = idObj;
                    this.loadDailies(this.selections.project.id);
                    break;
                case 7: // Broadcast
                    this.selections.broadcast = idObj;
                    this.loadBroadcasts(this.selections.project.id);
                    break;
                case 8: // Submit
                    await this.loadSubmits(null, this.messageEntityID);
                    break;
                case 9: // Task
                    this.selections.task = idObj;
                    this.loadTasks(this.selections.project.id, this.selections.taskType.id);
                    break;
            }
        },
    },
};
</script>

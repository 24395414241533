<template>
    <div v-if="editing || statusChangeInProgress" class="flex flex-column gap-4">
        <ck-editor
            v-if="description !== null"
            v-model="description"
            placeholder="Type searchable project description..."
        />
        <div v-if="!statusChangeInProgress" class="w-full flex justify-end gap-4">
            <v-btn :disabled="postInProgress" @click="editing = false">Cancel</v-btn>
            <v-btn :disabled="postInProgress || !changed" color="primary" @click="onSubmit">Save</v-btn>
        </div>
    </div>
    <div v-else class="flex flex-column gap-4">
        <div v-if="description" class="ck-content" v-html="description" />
        <div v-else class="text-xs text-gray-500 dark:text-gray-600">Add searchable description here...</div>
        <div v-if="$checkPermission('project.update')" class="w-full flex justify-end">
            <v-btn @click="editing = true">Edit</v-btn>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    props: {
        project: { type: Object, required: true },
        statusChangeInProgress: { type: Boolean, required: false, default: false},
    },
    data() {
        return {
            editing: false,
            postInProgress: false,
            initialDescription: null,
            description: null,
        };
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/getActiveProject',
        }),
        changed() {
            return this.initialDescription !== this.description;
        },
    },
    watch: {
        description(e) {
            if (this.statusChangeInProgress) {
                this.$emit('update', e);
            }
        },
    },
    mounted() {
        this.description = this.project.description || "";
        this.initialDescription = this.description;
    },
    methods: {
        onSubmit() {
            this.postInProgress = true;
            const postData = new FormData();
            postData.append('description', this.description);

            this.$axios
                .$post(`${process.env.prodAPI}/projects/${this.project.id}`, postData)
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    if (this.project.id === this.activeProject.id) {
                        const newProj = this.$clone(this.project);
                        newProj.description = this.description;
                        this.$store.commit('project/setActiveProject', newProj);
                    }
                    this.initialDescription = this.description;
                    this.difference = null;
                    this.editing = false;
                })
                .catch(this.$catchPost)
                .finally(() => {
                    this.postInProgress = false;
                });
        },
    },
};
</script>

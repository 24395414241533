<template>
    <nuxt-link
        :to="to"
        target="_blank"
        class="inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
        :class="{ completed, 'pointer-events-none': !permission, 'text-xs': !normalText }"
    >
        <!-- Now without a hand icon! -->
        <project-badge v-if="project" :project="project" icon-only :is-small="!isNotSmall" has-large-icon />
        <p class="mt-1" :class="labelClass">
            <span :class="{'px-2': !normalText }" :title="formattedLabel" v-html="formattedLabel"></span>
        </p>
    </nuxt-link>
</template>

<script>
export default {
    name: 'LinkWithHandIcon',
    props: {
        to: { type: String, required: true },
        label: { type: String, required: true },
        labelClass: { type: String, required: false, default: '' },
        completed: { type: String, required: false, default: '' },
        project: { type: Object, required: false, default: null },
        permission: { type: Boolean, required: false, default: true },
        normalText: { type:Boolean, required: false, default: false },
        isNotSmall: { type:Boolean, required: false, default: false }
    },
    computed: {
        formattedLabel() {
            const index = this.label.indexOf('/');
            // Ha van '/' jel
            if (index !== -1) {
                const beforeSlash = this.label.substring(0, index);
                const afterSlash = this.label.substring(index + 1);

                return '<b style="font-size: 12px">' + beforeSlash + '</b> /' + afterSlash;
            }
            return this.label;
        },
    },
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":_vm.$store.state.vuetify.dialog.overlayOpacity,"overlay-color":_vm.$store.state.vuetify.dialog.overlayColor,"width":"1128","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-body"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"resourceData",on:{"submit":function($event){$event.preventDefault();return _vm.saveModal.apply(null, arguments)}}},[_c('modal-header',{attrs:{"text":"Add new Feedback"},on:{"closeModal":_vm.closeModal}}),_vm._v(" "),_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"form-label"},[_vm._v("URL")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"relative"},[_c('v-text-field',{attrs:{"error-messages":errors,"disabled":!_vm.urlIsEditable,"solo":"","dense":"","required":""},model:{value:(_vm.feedback.url),callback:function ($$v) {_vm.$set(_vm.feedback, "url", $$v)},expression:"feedback.url"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(!_vm.urlIsEditable)?_c('v-btn',{staticClass:"absolute top-0 right-0",attrs:{"icon":""},on:{"click":function($event){_vm.urlIsEditable = !_vm.urlIsEditable}}},[_c('i',{staticClass:"las la-edit"})]):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.originalUrl !== _vm.feedback.url)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"absolute top-0 right-0 h-10 w-10 inline-flex items-center justify-center"},on),[_c('i',{staticClass:"las la-exclamation-triangle"})])]}}],null,true)},[_vm._v(" "),_c('span',{staticClass:"text-xs"},[_vm._v("URL has changed")])]):_vm._e()],1)],1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Type")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.config.feedback.types,"error-messages":errors,"item-text":function (item) { return item.label; },"solo":"","dense":"","required":"","return-object":""},model:{value:(_vm.feedback.type),callback:function ($$v) {_vm.$set(_vm.feedback, "type", $$v)},expression:"feedback.type"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"form-label"},[_vm._v("Content")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"FeedbackContent","name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.showEditor)?_c('ck-editor',{attrs:{"error-messages":errors,"set-focus":"","required":"","autofocus":""},model:{value:(_vm.feedback.content),callback:function ($$v) {_vm.$set(_vm.feedback, "content", $$v)},expression:"feedback.content"}}):_c('div',{staticClass:"flex justify-center items-center h-20"},[_c('i',{staticClass:"animate-spin las la-circle-notch",staticStyle:{"font-size":"2rem !important"}})])]}}],null,true)})],1),_vm._v(" "),_c('footer',{staticClass:"dialog-footer"},[_c('div',{staticClass:"col-span-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.closeModal}},[_vm._v("Back")])],1),_vm._v(" "),_c('div',{staticClass:"col-start-3 col-span-3"},[_c('v-btn',{attrs:{"block":"","color":"primary","type":"submit","disabled":invalid || _vm.postInProgress}},[_c('i',{staticClass:"las la-save mr-2"}),_vm._v("\n                            Save\n                        ")])],1)])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
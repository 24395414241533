<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="1128"
        persistent

    >
        <div class="dialog-body">
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form ref="resourceData" @submit.prevent="saveModal">
                    <modal-header text="Add new Feedback" @closeModal="closeModal" />

                    <div class="mb-6">
                        <label class="form-label">URL</label>
                        <validation-provider v-slot="{ errors }" name="URL" rules="required">
                            <div class="relative">
                                <v-text-field
                                    v-model="feedback.url"
                                    :error-messages="errors"
                                    :disabled="!urlIsEditable"
                                    solo
                                    dense
                                    required
                                />
                                <transition name="fade">
                                    <v-btn
                                        v-if="!urlIsEditable"
                                        icon
                                        class="absolute top-0 right-0"
                                        @click="urlIsEditable = !urlIsEditable"
                                        ><i class="las la-edit"
                                    /></v-btn>
                                </transition>
                                <transition name="fade">
                                    <v-tooltip v-if="originalUrl !== feedback.url" top>
                                        <template #activator="{ on }">
                                            <span
                                                class="absolute top-0 right-0 h-10 w-10 inline-flex items-center justify-center"
                                                v-on="on"
                                            >
                                                <i class="las la-exclamation-triangle" />
                                            </span>
                                        </template>
                                        <span class="text-xs">URL has changed</span>
                                    </v-tooltip>
                                </transition>
                            </div>
                        </validation-provider>
                    </div>

                    <div class="mb-6">
                        <label class="form-label">Type</label>
                        <validation-provider v-slot="{ errors }" name="Type" rules="required">
                            <v-select
                                v-model="feedback.type"
                                :items="config.feedback.types"
                                :error-messages="errors"
                                :item-text="(item) => item.label"
                                solo
                                dense
                                required
                                return-object
                            />
                        </validation-provider>
                    </div>

                    <div class="mb-6">
                        <label class="form-label">Content</label>
                        <validation-provider v-slot="{ errors }" vid="FeedbackContent" name="Content" rules="required">
                            <ck-editor
                                v-if="showEditor"
                                v-model="feedback.content"
                                :error-messages="errors"
                                set-focus
                                required
                                autofocus

                            />
                            <div v-else class="flex justify-center items-center h-20">
                                <i class="animate-spin las la-circle-notch" style="font-size: 2rem !important;" />
                            </div>
                        </validation-provider>
                    </div>

                    <footer class="dialog-footer">
                        <div class="col-span-2">
                            <v-btn block @click="closeModal">Back</v-btn>
                        </div>
                        <div class="col-start-3 col-span-3">
                            <v-btn block color="primary" type="submit" :disabled="invalid || postInProgress">
                                <i class="las la-save mr-2" />
                                Save
                            </v-btn>
                        </div>
                    </footer>
                </v-form>
            </validation-observer>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        showModal: { type: Boolean, required: false },
    },
    data() {
        return {
            urlIsEditable: false,
            passName: '',
            originalUrl: '',
            feedback: {
                type: null,
                content: '',
                url: '',
            },
            postInProgress: false,
            showEditor: false,
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
        }),
        dialog: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.$emit('updateModal', value);
            },
        },
    },
    watch: {
        dialog(e) {
            if (e === true) {
                this.feedback.url = this.$route.fullPath;
                this.originalUrl = this.feedback.url;
                this.feedback.type = this.config.feedback.types.find((data) => data.id === 2);
                setTimeout(() => { this.showEditor = true; }, 500);
            } else {
                this.feedback = {
                    type: null,
                    content: '',
                    url: '',
                };
                this.$refs.observer.reset();
                this.urlIsEditable = false;
                this.showEditor = false;
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit('updateModal', false);
        },
        saveModal() {
            this.postInProgress = true;
            const postData = new FormData();
            postData.append('type', this.feedback.type.id);
            postData.append('url', this.feedback.url);
            postData.append('content', this.feedback.content);

            this.$axios
                .$post(`${process.env.prodAPI}/feedbacks`, postData)
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.$emit('saveModal', res.result);
                })
                .catch(this.$catchPost)
                .finally(() => {
                    this.postInProgress = false;
                });
        },
    },
};
</script>

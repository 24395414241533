export const state = () => ({
    isPageLoading: false,
    tab: 'inbox',
    messages: {},
    showNewMessage: false,
    messageEntityID: null,
    messageType: null,
    messageRecipient: null,
    messageProject: null,
    messagePayload: null,
    messageIDs: null,
    refreshMessagesTable: false,
    unreadMessages: false,
    headerMessages: [],
});

export const getters = {
    getMessages(state) {
        return state.messages;
    },
    getTab(state) {
        return state.tab;
    },
    getShowNewMessage(state) {
        return state.showNewMessage;
    },
    getMessageEntityID(state) {
        return state.messageEntityID;
    },
    getMessageType(state) {
        return state.messageType;
    },
    getMessageRecipient(state) {
        return state.messageRecipient;
    },
    getMessageProject(state) {
        return state.messageProject;
    },
    getMessagePayload(state) {
        return state.messagePayload;
    },
    getRefreshMessagesTable(state) {
        return state.refreshMessagesTable;
    },
    getMessageIDs(state) {
        return state.messageIDs;
    },
    getUnreadMessages(state) {
        return state.unreadMessages;
    },
    getHeaderMessages(state) {
        return state.headerMessages;
    }
}

export const mutations = {
    setMessages(state, messages) {
        state.messages = messages;
    },
    setTab(state, tab) {
        state.tab = tab;
    },
    setIsPageLoading(state, status) {
        state.isPageLoading = status;
    },
    setShowNewMessage(state, status) {
        state.showNewMessage = status;
    },
    setMessageEntityID(state, entityId) {
        state.messageEntityID = entityId;
    },
    setMessageType(state, type) {
        state.messageType = type;
    },
    setMessageRecipient(state, recipient) {
        state.messageRecipient = recipient;
    },
    setMessageProject(state, project) {
        state.messageProject = project;
    },
    setMessagePayload(state, payload) {
        state.messagePayload = payload;
    },
    setRefreshMessagesTable(state, status) {
        state.refreshMessagesTable = status;
    },
    storeMessageIDs(state, ids) {
        state.messageIDs = ids;
    },
    setUnreadMessages(state, status) {
        state.unreadMessages = status;
    },
    setHeaderMessages(state, payload) {
        state.headerMessages = payload;
    }
}

export const actions = {
    setShowNewMessage(context, payload) {
        context.commit('setShowNewMessage', payload.state);
        context.commit('setMessageEntityID', payload.entityId);
        context.commit('setMessageType', payload.type);
        if (payload.payload) { context.commit('setMessagePayload', payload.payload); }
        if (payload.projectID) { context.commit('setMessageProject', payload.projectID); }
        if (payload.recipient) { context.commit('setMessageRecipient', payload.recipient); }
    },
    async fetchMessageTab({ commit }, tab) {
        return await this.$axios
            .$get(`${process.env.prodAPI}/messages/${tab}?scopes[]=created_meta&order=-createdAt&limit=25&page=1`)
            .then((res) => {
                commit('setMessages', res.result);
            });
    },
    fetchHeaderMessages(context) {
        this.$axios
            .$get(`${process.env.prodAPI}/messages/inbox?filters[]=seen:eq:0&limit=1`)
            .then((res) => {
                context.commit('setUnreadMessages', res.result.length > 0);
            })
            .catch((e) => {
                if (e.response?.data?.message) window.$nuxt.$notify({ title: e.response.data.message, type: 'error' });
            });
        this.$axios
            .$get(`${process.env.prodAPI}/messages/inbox?scopes[]=created_meta&order=-createdAt&limit=5&page=1`)
            .then((res) => {
                context.commit('setHeaderMessages', res.result);
            })
            .catch((e) => {
                if (e.response?.data?.message) window.$nuxt.$notify({ title: e.response.data.message, type: 'error' });
            });
    },
}
